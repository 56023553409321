import 'select2';
import 'select2/dist/js/i18n/fr';
import 'select2/dist/css/select2.min.css';

import Modal from 'bootstrap/js/dist/modal';

$(document).ready(function () {
  let select = $('.select2');
  if (select.length) {
    select.select2({
      closeOnSelect: false
    });
  }

  $('#copy-clipboard').on('click', (evt) => {
    let copyText = document.querySelector("#url-authorization");
    copyText.select();
    document.execCommand("copy");
  });

  let currentModal = $('#modal');
  if (currentModal.length) {
    let modal = new Modal(currentModal, {});

    $('.confirm-modal').on('click', (evt) => {
      let id = $(evt.currentTarget).data('identifier');
      $('#confirm-button').data('identifier', id);

      modal.show();
    });

    $('#confirm-button').on('click', (evt) => {
      let id = $(evt.currentTarget).data('identifier');
      $(`#form-${id}`).submit();
    });
  }
});